
import {setPageHeader} from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import ConfigutationsList from "../components/ConfigurationsList.vue";
import AddConfiguration from "../components/AddCongfiguration.vue";
import EditConfiguration from "../components/EditConfiguration.vue";

export default defineComponent({
	name: "system-configuration",
	components: {
		ConfigutationsList,
		AddConfiguration,
		EditConfiguration
	},

	setup() {
		onMounted(() => {
            setPageHeader({
                title: "message.SYSTEM_CONFIGURATIONS",
                actionButton: {
                    ability:'add_site',
                    pageAction: {
                        action: "addSystemConfigAction",
                    },
                    button: {
                        icon: "bi bi-plus",
                        openModal: true,
                    }
                },
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.SYSTEM", link: "#"},
                    { name: "message.SETTINGS", link: "/system/settings"},
                    { name: "message.SYSTEM_CONFIGURATIONS"}
                ]
            })
		});
	},
});
