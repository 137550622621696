import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfigutationsList = _resolveComponent("ConfigutationsList")!
  const _component_AddConfiguration = _resolveComponent("AddConfiguration")!
  const _component_EditConfiguration = _resolveComponent("EditConfiguration")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_ConfigutationsList)
    ]),
    _createVNode(_component_AddConfiguration),
    _createVNode(_component_EditConfiguration)
  ], 64))
}