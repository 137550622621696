import { gql } from "graphql-tag";
import { CONFIG_FIELDS } from "@/modules/common/configuration/graphql/Fragments";

export const CREATE_CONFIG = gql`
	mutation CreateConfig($input: SystemConfigInput!) {
		create_system_config(system_config: $input) {
			...ConfigurationFields
		}
	}
	${CONFIG_FIELDS}
`;

export const UPDATE_CONFIG = gql`
	mutation UpdateConfig($input: SystemConfigInput!) {
		update_system_config(system_config: $input) {
			...ConfigurationFields
		}
	}
	${CONFIG_FIELDS}
`;

export const DELETE_CONFIG = gql`
	mutation DeleteConfig($id: Int!) {
		delete_system_config(id: $id)
	}
`;

export default { CREATE_CONFIG, UPDATE_CONFIG, DELETE_CONFIG };
