
import { Modal } from "bootstrap";
import { defineComponent, ref, inject, watchEffect } from "vue";
import { Input, Select } from "@/components/input-elements";
import { ConfigAddFormData } from "../interfaces";
import { Apollo, Notify } from "@/core/services";
import { GET_CONFIG } from "@/modules/common/configuration/graphql/Queries";
import { CREATE_CONFIG } from "../graphql/Mutations";
import { useStore } from "vuex";
import { GET_COUNTRIES } from "@/modules/common/countries/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new config",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const addConfigForm = ref<null | HTMLFormElement>(null);
		const sites = ref([]) as Record<any, any>;
		const store = useStore();
		const i18n = useI18n();
		const countries = ref([]) as Record<any, any>;
		const showCountryInfo = ref([]) as Record<any, any>;
		const disabled = ref(false);
		const getSites = store.getters.getAllSites.data.sites;
		const countriesData = Apollo.readQuery({
			query: GET_COUNTRIES,
		});
		// Given Site Form Interface
		const configData = ref<ConfigAddFormData>({
			name: "",
			country_id: null,
			site_id: null,
			is_default: false,
		});

		// Get All Sites
		watchEffect(() => {
			sites.value = [];

			getSites.forEach((element, index) => {
				sites.value.push({
					label: element.name,
					value: element.id,
				});
			});
		});

		// Get Countries
		const getCountries = () => {
			countries.value = [];

			const country = [] as Record<any, any>;
			countriesData.countries.forEach((element) => {
				country.push({
					label: element.name,
					value: element.id,
					locale: element.locale,
					currency: element.currency,
				});
			});

			countries.value = country;
		};

		const changeConfig = (id: number) => {
			showCountryInfo.value = [];
			const config = countries.value.find((element) => element.value == id);
			showCountryInfo.value.push({
				Currency: config.currency,
				Locale: config.locale,
			});
		};

		// Submit Hanlder Request
		const submitHandlerForm = async () => {
			addConfigForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						name: configData.value.name,
						is_default: configData.value.is_default == false ? 0 : 1,
						country_id: configData.value.country_id,
						site_id: configData.value.site_id,
					};

					if (disabled.value == false) {
						loading.value = true;
						await Apollo.mutate({
							mutation: CREATE_CONFIG,
							variables: { input: formData },
							update: (store, { data: { create_system_config } }) => {
								const pervious_record = store.readQuery({
									query: GET_CONFIG,
								}) as Record<any, any>;

								store.writeQuery({
									query: GET_CONFIG,
									data: {
										system_configuration: [create_system_config, ...pervious_record.system_configuration],
									},
								});
								modal.value.hide();
								loading.value = false;
								Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
							},
						}).catch(() => {
							loading.value = false;
						});
					} else {
						Notify.error(i18n.t("message.SOMETHING_WENT_WRONG"));
					}
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			getCountries();
			emitter.emit("clearInput");
			addConfigForm.value?.resetFields();
		};

		const changeSite = (id: number) => {
			const config = Apollo.readQuery({
				query: GET_CONFIG,
			});

			const siteCountryId = getSites.find((ele) => ele.id == id);

			configData.value.country_id = siteCountryId.country_id;
			changeConfig(siteCountryId.country_id);

			const exitsSite = config.system_configuration.find((ele) => ele.site.id == id);
			if (exitsSite != undefined) {
				Notify.error(i18n.t("message.SELECTED_SITE_ALREADY_ASSOCIATED_WITH_OTHER_CONFIGURATION"));
				disabled.value = true;
			} else {
				disabled.value = false;
			}
		};
		// Emitter To Open Model
		emitter.on("addSystemConfigAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_config")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			configData,
			addConfigForm,
			sites,
			countries,
			showCountryInfo,
			disabled,
			changeSite,
			submitHandlerForm,
			changeConfig,
		};
	},
});
