
import { Apollo, Notify } from '@/core/services';
import { GET_CONFIG } from '@/modules/common/configuration/graphql/Queries';
import { defineComponent, ref, inject, watchEffect, onMounted } from 'vue';
import { useStore } from 'vuex';
import { DELETE_CONFIG } from '../graphql/Mutations';
import { useI18n } from 'vue-i18n';
import Table from '../../../../components/Table/Table.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';
// import InnerLoader from "../../../../components/InnerLoader.vue";

export default defineComponent({
    name: 'configuration-list',
    components: { Table },
    setup() {
        const store = useStore();
        const emitter: any = inject('emitter');
        const loader = ref(false);
        const configData: any = ref([]);
        const i18n = useI18n();
        const activeIndex = ref(-1);
        const permissions = store.getters.getPermissions;

        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.SITE',
                key: 'site'
            },
            {
                label: 'message.COUNTRY',
                key: 'country'
            },
            {
                label: 'message.LOCALE',
                key: 'locale'
            },
            {
                label: 'message.CURRENCY',
                key: 'currency'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        watchEffect(() => {
            configData.value = store.getters?.getAllConfigs?.data?.system_configuration;
        });

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const handleEdit = (config: Record<any, any>) => {
            emitter.emit('editSystemConfigAction', config);
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_CONFIG,
                        variables: { id: id },
                        update: (store, { data: { delete_system_config } }) => {
                            const read_data = store.readQuery({
                                query: GET_CONFIG
                            }) as Record<any, any>;
                            const data = read_data.system_configuration.filter((t: any) => t.id !== delete_system_config);

                            store.writeQuery({
                                query: GET_CONFIG,
                                data: {
                                    system_configuration: {
                                        ...read_data.system_configuration,
                                        data: [...data]
                                    }
                                }
                            });
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        onMounted(() => {
            document.addEventListener('click', handleClickOutside);
        });

        return {
            configData,
            columns,
            permissions,
            loader,
            handleEdit,
            handleDelete,
            activeIndex,
            dropdownHandler,
            handleClickOutside
        };
    }
});
